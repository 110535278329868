import {graphql, useStaticQuery} from "gatsby";
import useSiteSettings from "~utils/useSiteSettings";

const useMenuData = () => {
  const menuData = useStaticQuery(graphql`
    query {
      allSanityBrand(sort: {order: ASC, fields: slug___current}) {
        nodes {
          title
          _rawContent(resolveReferences: {maxDepth: 5})
          _id
          _type
          slug {
            current
          }
          content {
            _type
          }
        }
      }
    }
  `);

  const {
    showrooms,
    journalText,
    journalLinkText,
    aboutText,
    aboutLinkText,
    aboutImage,
    featuredArticles,
    storeCategories,
  } = useSiteSettings();

  const links = [
    {
      key: `aboutUs`,
      title: `About Us`,
      id: `aboutUs`,
      link: `/about`,
      type: `listItem`,
    },
    {
      key: `Showrooms`,
      title: `Showrooms`,
      id: `Showrooms`,
      link: `/showrooms`,
      type: `listItem`,
    },
    {
      key: `Brands`,
      title: `Brands`,
      id: `Brands`,
      link: `/brands`,
      type: `listItem`,
    },
    {
      key: `store`,
      title: `Products`,
      id: `store`,
      link: `/products`,
      type: `listItem`,
    },
    {
      key: `journal`,
      title: `Journal`,
      id: `journal`,
      link: `/journal`,
      type: `listItem`,
    },
    {
      key: `contact`,
      title: `Contact`,
      id: `contact`,
      link: `/contact`,
      type: `link`,
    },
    {
      key: `trade`,
      title: `Trade`,
      id: `trade`,
      link: `/account`,
      type: `link`,
    },
  ];

  return {
    links: links,
    aboutText: aboutText,
    aboutLinkText: aboutLinkText,
    aboutImage: aboutImage,
    showrooms: showrooms,
    brands: menuData.allSanityBrand?.nodes,
    storeCategories: storeCategories,
    journalText: journalText,
    journalLinkText: journalLinkText,
    featuredArticles: featuredArticles,
  };
};

export default useMenuData;
