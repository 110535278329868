import React from "react";
import PropTypes from "prop-types";
import {css} from "@emotion/react";
import Image from "~components/Image";
import RichText from "~components/RichText";

const Figure = ({className, image, caption, aspectRatio}) => {
  return (
    <figure className={className}>
      <Image
        asset={image}
        aspectRatio={aspectRatio}
        css={css`
          margin-bottom: 20px;
        `}
      />
      <figcaption className="h6 caption">
        <RichText content={caption} />
      </figcaption>
    </figure>
  );
};

Figure.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  video: PropTypes.object,
  caption: PropTypes.array,
  aspectRatio: PropTypes.number,
};

Figure.defaultProps = {};

export default Figure;
