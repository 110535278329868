import React from "react";
import PropTypes from "prop-types";
import {css} from "@emotion/react";
import {breakpoints} from "~styles/global";
import Section from "~components/Section";
import {Rarr} from "~components/Svg";
import {Link} from "gatsby";
import Button from "../../blocks/Button";
import {CrossThick} from "../../Svg";

const AnnouncementBar = React.forwardRef(({className, announcement, link, close, onClick}, ref) => {
  const {mobile} = breakpoints;

  return (
    <div
      className={className}
      ref={ref}
      css={css`
        display: grid;
        min-height: 30px;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: max-content max-content max-content;
        /* border-bottom: 1px solid var(--black); */
        background: var(--orange);
        color: var(--white);
      `}
    >
      <Button
        noUnderline
        onClick={onClick}
        css={css`
          grid-column: 2;
        `}
      >
        <span
          css={css`
            margin-right: 4px;
          `}
        >
          {announcement}
        </span>
        <Rarr
          css={css`
            width: 10px;
            height: 10px;
            transform: translateY(0.5px);
            ${mobile} {
              width: 7px;
              height: 7px;
            }
          `}
        />
      </Button>
      <button
        onClick={close}
        css={css`
          justify-self: end;
        `}
      >
        <CrossThick
          css={css`
            width: 7px;
            height: 7px;
            margin-right: 14px;
            display: block;
            ${mobile} {
              margin-right: 10px;
            }
          `}
        />
      </button>
    </div>
  );
});

AnnouncementBar.propTypes = {
  announcement: PropTypes.string,
  link: PropTypes.string,
  close: PropTypes.func,
};

AnnouncementBar.defaultProps = {
  // announcement: `anouncement here over one line`,
  // link: `/`,
};

export default AnnouncementBar;
