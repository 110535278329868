import {graphql, useStaticQuery} from "gatsby";

const usePlaceholder = () => {
  const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings(_id: {regex: "/(drafts\\\\.)?siteSettings/"}) {
        _rawPlaceholderImage(resolveReferences: {maxDepth: 5})
      }
    }
  `);

  const image = siteSettings.sanitySiteSettings._rawPlaceholderImage;

  return image;
};

export default usePlaceholder;
