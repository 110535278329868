import React from "react";
import PropTypes from "prop-types";
import {css} from "@emotion/react";
import {breakpoints} from "~styles/global";
import Figure from "~components/modules/Figure";

const DoubleImageModule = ({className, imageLeft, imageRight, captionLeft, captionRight}) => {
  const {mobile} = breakpoints;

  return (
    <div
      className={className}
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 20px;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        ${mobile} {
          grid-column-gap: 10px;
        }
      `}
    >
      <div
        css={css`
          grid-column: 2/6;
          align-self: center;
          ${mobile} {
            grid-column: 3/11;
            margin-bottom: 100px;
          }
        `}
      >
        <Figure image={imageLeft} caption={captionLeft} />
      </div>
      <div
        css={css`
          grid-column: 7/12;
          ${mobile} {
            grid-column: span 12;
          }
        `}
      >
        <Figure image={imageRight} caption={captionRight} />
      </div>
    </div>
  );
};

DoubleImageModule.propTypes = {
  imageLeft: PropTypes.string,
  imageRight: PropTypes.string,
  captionLeft: PropTypes.array,
  captionRight: PropTypes.array,
};

DoubleImageModule.defaultProps = {
  captionLeft: [],
  captionRight: [],
};

export default DoubleImageModule;
