import React, {useEffect, useLayoutEffect, useState} from "react";
import PropTypes from "prop-types";
import {css} from "@emotion/react";
import {breakpoints} from "~styles/global";
import {navigate, Link} from "gatsby";

const LevelOneMenuList = ({className, links, onUpdate, updating, spread}) => {
  const {tablet} = breakpoints;

  const [selectedItem, setSelectedItem] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", (e) => {
      setWindowWidth(window.innerWidth);
    });
    return window.removeEventListener("resize", (e) => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (onUpdate && selectedItem) {
      onUpdate(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (updating) {
      setSelectedItem(null);
    }
  }, [updating]);

  const navigateToLink = (link) => {
    let url = link;
    navigate(url);
  };

  return (
    <div
      className={className}
      css={css`
        ${tablet} {
          height: auto;
          display: block;
          columns: 1;
        }
      `}
    >
      {links?.map((listItem) =>
        listItem.type === `link` ? (
          <Link
            key={listItem.key}
            to={listItem.link}
            className="h4"
            css={css`
              color: ${selectedItem === null
                ? `inherit`
                : selectedItem === listItem.title
                  ? `inherit`
                  : `var(--grey)`};
              transition: 0.2s color;
              margin-bottom: 23px;
              text-align: left;
              display: block;
              &:hover {
                color: inherit;
              }
              ${tablet} {
                margin-bottom: ${spread ? `34px` : `20px`};
              }
            `}
          >
            {listItem.title}
          </Link>
        ) : (
          <button
            key={listItem.key}
            onClick={
              windowWidth <= 1440
                ? () => setSelectedItem(listItem.title)
                : () => navigateToLink(listItem.link)
            }
            onMouseEnter={windowWidth >= 1441 ? () => setSelectedItem(listItem.title) : null}
            className="h4"
            css={css`
              color: ${selectedItem === null
                ? `inherit`
                : selectedItem === listItem.title
                  ? `inherit`
                  : `var(--grey)`};
              transition: 0.2s color;
              margin-bottom: 23px;
              text-align: left;
              display: block;
              &:hover {
                color: inherit;
              }
              ${tablet} {
                margin-bottom: ${spread ? `34px` : `20px`};
              }
            `}
          >
            {listItem.title}
          </button>
        ),
      )}
    </div>
  );
};

LevelOneMenuList.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

LevelOneMenuList.defaultProps = {
  links: [
    {
      key: `item1`,
      title: `Title 1`,
      id: `id1`,
    },
    {
      key: `item2`,
      title: `Title 2`,
      id: `id2`,
    },
    {
      key: `item3`,
      title: `Title 3`,
      id: `id3`,
      link: `/`,
    },
  ],
};

export default LevelOneMenuList;
