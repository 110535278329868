import React from "react";
import {navigate} from "gatsby";
import PropTypes from "prop-types";
import {css} from "@emotion/react";
import {breakpoints} from "~styles/global";
import Section from "~components/Section";
import SearchInput from "~components/blocks/SearchInput";
import {useSiteState} from "~context/siteContext";

const SearchOverlay = ({className, initialTerm, onUpdate, onUpdateShow}) => {
  const {mobile} = breakpoints;

  const [siteState, setSiteState] = useSiteState();

  const handleSubmit = (e) => {
    const term = e.target[0].value;
    setSiteState({...siteState, searchOpen: false});
    navigate(`/search?term=${term}`);
    window.setTimeout(() => {
      e.target[0].value = null;
    }, 400);
  };

  return (
    <>
      <Section
        className={className}
        css={css`
          padding-top: 146px;
          padding-bottom: 94px;
          ${mobile} {
            padding-top: 136px;
            padding-bottom: 94px;
          }
        `}
      >
        <div
          css={css`
            grid-column: 4/10;
            ${mobile} {
              grid-column: span 11;
            }
          `}
        >
          <div
            className="h4"
            css={css`
              margin-bottom: 70px;
              ${mobile} {
                margin-bottom: 36px;
              }
            `}
          >
            What are you looking for?
          </div>
          <SearchInput
            css={css`
              margin-bottom: 0;
            `}
            onSubmit={(e) => handleSubmit(e)}
            focusOnOpen
          />
        </div>
      </Section>
    </>
  );
};

SearchOverlay.propTypes = {
  initialTerm: PropTypes.string,
  onUpdate: PropTypes.func,
  onUpdateShow: PropTypes.func,
};

SearchOverlay.defaultProps = {
  initialTerm: null,
  onUpdate: (e) => console.log(e),
  onUpdateShow: (e) => console.log(e),
};

export default SearchOverlay;
