import React from "react";
import {Global, css} from "@emotion/react";
import {useSiteState} from "~context/siteContext";

export const breakpoints = {
  mobile: "@media (max-width: 768px)",
  tablet: "@media (max-width: 1024px)",
  desktop: "@media (max-width: 1230px)",
  desktopUp: "@media (min-width: 1025px)",
};
const {mobile, tablet} = breakpoints;

const GlobalStyles = () => (
  <>
    <Global styles={reset} />
    <Global styles={styles} />
  </>
);

const styles = css`
  /* Animations */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  html {
    background-color: var(--beige);
    scroll-padding-top: 104px;
    ${mobile} {
      scroll-padding-top: 76px;
    }
  }

  body,
  .body {
    font-family: "BasisGrotesque", sans-serif;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.01em;
    font-weight: 400;
    text-transform: none;
    ${tablet} {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0em;
    }
  }

  :root {
    --white: #ffffff;
    --black: #000000;
    --dark-grey: #716f6e;
    --beige: #f6f1eb;
    --orange: #9e4c00;
    --grey: #b7b7b7;
    --standard: all 0.2s ease-in-out;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    ${tablet} {
      font-size: 13px;
      line-height: 20px;
    }
  }

  h5,
  .h5 {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.03em;
    ${tablet} {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0em;
    }
  }

  h6,
  .h6 {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    ${tablet} {
      font-size: 10px;
      line-height: 13px;
    }
  }

  p {
    margin-bottom: 1.2em;
    &:last-of-type {
      margin-bottom: 0;
    }
    ${mobile} {
      margin-bottom: 1.3em;
    }
  }

  input:not([type="radio"]),
  textarea {
    background: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-bottom: 1px solid var(--black);
    padding: 10px 0;
    display: block;
    width: 100%;
    margin-bottom: 25px;
    font-size: 13px;
    letter-spacing: 0.03em;
    font-family: inherit;
    box-sizing: border-box;
    border-radius: 0;
    ${tablet} {
      font-size: 12px;
      letter-spacing: 0em;
      margin-bottom: 20px;
    }
    &::placeholder {
      color: var(--black);
      opacity: 1;
    }
    &:focus {
      outline: none;
      box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.05);
      padding: 10px 12px;
      &::placeholder {
        opacity: 0;
      }
    }
  }

  textarea {
    border: 1px solid var(--black);
    max-width: 100%;
    min-width: 100%;
    padding: 20px 14px;
    resize: none;
    &:focus {
      padding: 20px 14px;
    }
    ${tablet} {
      padding: 14px;
      &:focus {
        padding: 14px;
      }
    }
  }

  .caption {
    a {
      border-bottom: 1px solid var(--black);
      margin-bottom: 2px;
      display: inline-block;
    }
  }

  .carousel-image {
    opacity: 0 !important;
    transition: opacity 1s ease !important;
    &.is-selected {
      opacity: 1 !important;
    }
  }
`;
const reset = css`
  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul,
  ol,
  blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  button {
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: inherit;
    &:active {
      color: inherit;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 500px white inset !important;
  }

  figure {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  * {
    overflow-anchor: none;
  }
`;

export default GlobalStyles;
