import React, {useEffect, useLayoutEffect, useState} from "react";
import {navigate, Link} from "gatsby";
import PropTypes from "prop-types";
import {css} from "@emotion/react";
import {breakpoints} from "~styles/global";
import resolveLink from "~utils/resolveLink";
import {useSiteState, useUrlLocation} from "~context/siteContext";

const MenuList = ({
  className,
  links,
  onUpdate,
  brands,
  updating,
  spread,
  parent,
  onMouseEnter,
  clickableOntablet,
}) => {
  const {tablet} = breakpoints;
  const [selectedItem, setSelectedItem] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);
  const [siteState, setSiteState] = useSiteState();

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", (e) => {
      setWindowWidth(window.innerWidth);
    });
    return window.removeEventListener("resize", (e) => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (updating) {
      setSelectedItem(null);
    }
  }, [updating]);

  useEffect(() => {
    if (onUpdate && selectedItem) {
      const type = selectedItem._type;
      onUpdate(selectedItem, type);
    }
  }, [selectedItem]);

  const changeSelectedItem = (listItem) => {
    setSelectedItem(listItem);
    if (onUpdate && selectedItem) {
      const type = selectedItem._type;
      onUpdate(selectedItem, type);
    }
  };

  const navigateToLink = (doc) => {
    if (!doc._type || !doc.slug?.current) return null;
    let url;
    if (["brandFilter"].includes(doc._type)) {
      url = resolveLink(
        doc._type,
        doc.title,
        parent?.slug?.current || doc.defaultParentCategory?.slug?.current,
      );
    } else {
      url = resolveLink(
        doc._type,
        doc.slug.current,
        parent?.slug?.current || doc.defaultParentCategory?.slug?.current,
      );
    }
    setSiteState({
      ...siteState,
      menuOpen: false,
      searchOpen: false,
      searchOpen: false,
    });
    navigate(url);
  };

  const isProductCategory = parent?._type === "productParentCategory";

  return (
    <div
      onMouseEnter={onMouseEnter}
      className={className}
      css={css`
        ${tablet} {
          height: auto;
          display: block;
          columns: ${brands ? `2` : `1`};
        }
      `}
    >
      {/* {JSON.stringify(parent?._type)} */}
      {links?.map((listItem) =>
        listItem.link ? (
          <Link
            key={listItem.key}
            to={listItem.link}
            className="h4"
            css={css`
              color: ${selectedItem === null
                ? `inherit`
                : selectedItem === listItem
                  ? `inherit`
                  : `var(--grey)`};
              transition: 0.2s color;
              margin-bottom: 23px;
              text-align: left;
              display: block;
              &:hover {
                color: inherit;
              }
              ${tablet} {
                margin-bottom: ${spread ? `34px` : `20px`};
              }
            `}
          >
            {listItem.title}
          </Link>
        ) : (
          <button
            key={listItem.key}
            onClick={
              windowWidth <= 1440 && !isProductCategory
                ? () => setSelectedItem(listItem)
                : () => navigateToLink(listItem)
            }
            // onClick={() => setSelectedItem(listItem)}
            onMouseEnter={windowWidth >= 1441 ? () => changeSelectedItem(listItem) : null}
            className="h4"
            css={css`
              color: ${selectedItem === null
                ? `inherit`
                : selectedItem === listItem
                  ? `inherit`
                  : `var(--grey)`};
              transition: 0.2s color;
              margin-bottom: 23px;
              text-align: left;
              display: block;
              &:hover {
                color: inherit;
              }
              ${tablet} {
                margin-bottom: ${spread ? `34px` : `20px`};
              }
            `}
          >
            {listItem._type === `singleCategory` ? listItem.category?.title : listItem.title}
          </button>
        ),
      )}
    </div>
  );
};

MenuList.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  brands: PropTypes.bool,
};

MenuList.defaultProps = {};

export default MenuList;
