import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {mobile, tablet} from "~styles/global";
import {Cross} from "~components/Svg";
import RichText from "~components/RichText";
import NewsletterSignupForm from "~components/blocks/NewsletterSignupForm";

const SignUpPopUp = ({className, heading, text, close, successMessage}) => {
  return (
    <Wrap className={className}>
      <Close onClick={close}>
        <StyledCross />
      </Close>
      <Inner>
        <Heading>{heading}</Heading>
        <Text>
          <RichText content={text} />
        </Text>
        <NewsletterSignupForm
          successMessage={successMessage}
          buttonText={"Join Now"}
          centered={true}
        />
      </Inner>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: grid;
  align-items: center;
  position: relative;
  padding: 44px 60px 50px;
  background: var(--beige);
  max-width: 450px;
  box-sizing: border-box;
  margin: 25px;
  ${mobile} {
    max-width: 360px;
    padding: 34px 40px 40px;
  }
`;
const Close = styled.button`
  position: absolute;
  z-index: 1;
  top: 26px;
  right: 26px;
  width: 20px;
  height: 20px;
`;
const StyledCross = styled(Cross)`
  width: 16px;
  height: 16px;
  ${mobile} {
    width: 12px;
    height: 12px;
  }
`;
const Inner = styled.div`
  display: grid;
  text-align: center;
`;
const Heading = styled.h4`
  margin-bottom: 32px;
  ${mobile} {
    margin-bottom: 22px;
  }
`;
const Text = styled.div`
  margin-bottom: 40px;
  ${mobile} {
    margin-bottom: 30px;
  }
`;

SignUpPopUp.propTypes = {
  className: PropTypes.string,
  successMessage: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.array,
  close: PropTypes.func,
};

export default SignUpPopUp;
