import React, {useState} from "react";
import PropTypes from "prop-types";
import {css} from "@emotion/react";
import {breakpoints} from "~styles/global";
import Image from "~components/Image";
import {Link} from "gatsby";

const ArticleCard = ({className, image, link, categories, date, title}) => {
  const {mobile} = breakpoints;
  const [hovered, setHovered] = useState(false);

  return (
    <Link
      className={className}
      to={link}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        css={css`
          overflow: hidden;
          margin-bottom: 28px;
          ${mobile} {
            margin-bottom: 20px;
          }
        `}
      >
        <div
          css={css`
            transform: ${hovered ? `scale(1.03)` : `scale(1)`};
            transition: 0.3s ease-in-out transform;
          `}
        >
          <Image asset={image} aspectRatio={0.719} />
        </div>
      </div>
      <div
        className="h6"
        css={css`
          display: grid;
          grid-template-columns: 1fr max-content;
          margin-bottom: 22px;
          ${mobile} {
            grid-template-columns: 1fr max-content 1fr;
            margin-bottom: 20px;
          }
        `}
      >
        <div>{categories?.map((category) => category.title).join(`, `)}</div>
        <div>{date}</div>
      </div>
      <div>{title}</div>
    </Link>
  );
};

ArticleCard.propTypes = {
  image: PropTypes.object,
  link: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ),
  date: PropTypes.string,
  title: PropTypes.string,
};

ArticleCard.defaultProps = {
  categories: [
    {
      title: `category1`,
    },
    {
      title: `category2`,
    },
    {
      title: `category3`,
    },
  ],
  date: `17.08.20`,
  title: `Victoria Point Foyer by Atoma Studio`,
  link: `/`,
};

export default ArticleCard;
