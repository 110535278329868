import React from "react";
import PropTypes from "prop-types";
import {css} from "@emotion/react";
import {Link} from "gatsby";
import {breakpoints} from "~styles/global";
import {ChevronDown, Darr} from "../../Svg";

const Button = ({
  children,
  to,
  onClick,
  className,
  darr,
  chevronDown,
  noUnderline,
  type,
  noLink,
  external,
}) => {
  const {tablet} = breakpoints;

  const buttonCss = `
    display: grid;
    align-items: center;
    grid-template-columns: ${chevronDown || darr ? `max-content max-content ` : `max-content`};
    position: relative;
    > span{
      position: relative;
    }
    > span:after{
      content: '';
      width: 100%;
      height: 1px;
      background-color: ${chevronDown || noUnderline ? `none` : `var(--black)`};
      transition: transform 0.2s;
      position: absolute;
      bottom: 0px;
      display: block;
      transform: translateY(1px);
    }
    &:hover{
      > span{
        &:after{
          transform: translateY(4px);
        }
      }
    }
  `;

  const darrCss = css`
    width: 10px;
    height: 10px;
    margin-left: 4px;
    ${tablet} {
      width: 8px;
      height: 8px;
    }
  `;

  const chevronCss = css`
    width: 12px;
    height: 12px;
    margin-left: 2px;
    ${tablet} {
      width: 10px;
      height: 10px;
      margin-left: 8px;
    }
  `;
  if (noLink) {
    return (
      <span
        css={css`
          ${buttonCss}
          display: inline-grid;
        `}
        className={className + " h6"}
      >
        <span>{children}</span>
        {darr && <Darr css={darrCss} />}
        {chevronDown && <ChevronDown css={chevronCss} />}
      </span>
    );
  } else if (to && (to?._type === "externalLink" || external)) {
    return (
      <>
        <a
          href={to}
          className={className}
          target="blank"
          css={css`
            display: block;
          `}
        >
          <button
            css={css`
              ${buttonCss}
            `}
            className="h6"
          >
            <span>{children}</span>
            {darr && <Darr css={darrCss} />}
            {chevronDown && <ChevronDown css={chevronCss} />}
          </button>
        </a>
      </>
    );
  } else if (to) {
    return (
      <>
        <Link
          to={to}
          className={className}
          css={css`
            display: block;
          `}
        >
          <button
            css={css`
              ${buttonCss}
            `}
            className="h6"
          >
            <span>{children}</span>
            {darr && <Darr css={darrCss} />}
            {chevronDown && <ChevronDown css={chevronCss} />}
          </button>
        </Link>
      </>
    );
  } else if (onClick) {
    return (
      <button
        css={css`
          ${buttonCss}
        `}
        className={className + " h6"}
        type={type ?? "button"}
        onClick={onClick}
      >
        <span>{children}</span>
        {darr && <Darr css={darrCss} />}
        {chevronDown && <ChevronDown css={chevronCss} />}
      </button>
    );
  } else {
    return null;
  }
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  to: PropTypes.string,
  onClick: PropTypes.func,
  noUnderline: PropTypes.bool,
  type: PropTypes.string,
  noLink: PropTypes.bool,
};

Button.defaultProps = {
  children: "link",
  noUnderline: false,
  noLink: false,
};

export default Button;
