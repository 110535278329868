const resolveLink = (type, slug, parent) => {
  if (!type || !slug) return null;

  if (parent && type === "productCategory") {
    return `/category/${parent}/${slug}`;
  }

  switch (type) {
    case "home":
      return `/`;
    case "article":
      return `/journal/${slug}`;
    case "product":
      return `/product/${slug}`;
    case "brandFilter":
      return `/products?brands=${slug}`;
    case "categoryFilter":
      return `/products?categories=${slug}`;
    case "productCategory":
      return `/category/${slug}`;
    case "brand":
      return `/brands/${slug}`;
    case "productParentCategory":
      return `/category/${slug}`;
    // hard coded all brands link
    case "allBrands":
      return `/brands`;
    // hard coded all products link
    case "allProducts":
      return `/products`;
    default:
      return `/${slug}`;
  }
};

export default resolveLink;
