import React from "react";
import PropTypes from "prop-types";
import {css} from "@emotion/react";
import {breakpoints} from "~styles/global";

const QuoteModule = ({className, quote, attribution}) => {
  const {mobile} = breakpoints;

  return (
    <div
      className={className}
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 20px;
        ${mobile} {
          grid-column-gap: 10px;
        }
      `}
    >
      <div
        css={css`
          grid-column: span 12;
          padding: ${attribution ? `34px 0 30px` : `34px 0`};
          border-top: 1px solid var(--grey);
          border-bottom: 1px solid var(--grey);
          ${mobile} {
            grid-column: span 12;
            padding: 30px 0;
          }
        `}
      >
        <h4
          css={css`
            margin-bottom: ${attribution ? `34px` : `0px`};
            ${mobile} {
              margin-bottom: ${attribution ? `32px` : `0px`};
            }
          `}
        >
          {quote}
        </h4>
        {attribution && <h6>– {attribution}</h6>}
      </div>
    </div>
  );
};

QuoteModule.propTypes = {
  quote: PropTypes.string,
  attribution: PropTypes.string,
};

QuoteModule.defaultProps = {
  quote: `X-glass mimics the natural patterns of marble and wood, and being digitally printed means that is has no environmental impact.`,
  attribution: `credit if required`,
};

export default QuoteModule;
