import encode from "~utils/encode";
import jsonp from "jsonp";

const sendToMailchimp = (email, fname, lname, callback) => {
  const mailchimpAjaxAction =
    "https://misura.us17.list-manage.com/subscribe/post-json?u=a217f8f6827a4593a35d1b9b8&amp;id=692d319db0&";

  let dataObj = {
    EMAIL: email,
  };

  if (fname) dataObj = {...dataObj, FNAME: fname};

  if (lname) dataObj = {...dataObj, LNAME: lname};

  const data = encode(dataObj);

  jsonp(`${mailchimpAjaxAction}${data}`, {param: "c"}, (err, data) => {
    callback(err, data);
  });
};

export default sendToMailchimp;
