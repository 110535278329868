import React, {useState, useContext, useEffect, useLayoutEffect} from "react";
import Cookies from "js-cookie";
import axios from "axios";
import sanityClient from "@sanity/client";
import {Loader} from "google-maps";
import useSiteSettings from "~utils/useSiteSettings";
import {useLocation} from "@reach/router";
import ShopifyClient from "shopify-buy";

// Note: It's essential that we use the function version of setState in many places here, especially on load, as we have lots of the same state being manipulated in quick succession

const initialSiteState = {
  account: undefined,
  wholesale: false,
  mapsApi: undefined,
  cart: [],
  wishlist: [],
  menuOpen: false,
  hideIcons: false,
  searchOpen: false,
  location: undefined,
};

export const SiteContext = React.createContext({
  siteState: initialSiteState,
  setSiteState: undefined,
});

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  apiVersion: "2021-07-22",
  useCdn: false,
});

const shopifyClient = ShopifyClient.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN,
  domain: process.env.GATSBY_SHOPIFY_STORE,
});

export const SiteStore = ({children}) => {
  const {gmapsApiKey} = useSiteSettings();
  const [siteState, setSiteState] = useState(initialSiteState);
  const [init, setInit] = useState(false);
  const [wishlistRetrieved, setWishlistRetrieved] = useState(false);

  const destroyCartIfPurchased = () => {
    const id = Cookies.get("M_CHECKOUT_ID");
    if (id) {
      shopifyClient.checkout.fetch(id).then((checkout) => {
        if (checkout.completedAt) {
          Cookies.remove("M_CHECKOUT_ID");
          localStorage.removeItem("M_CART");
          setSiteState((prevState) => ({
            ...prevState,
            cart: [],
          }));
        }
      });
    }
  };

  // set the accountToken as a cookie as soon as customer logs in
  useEffect(() => {
    if (init) {
      if (siteState.account?.token) {
        Cookies.set("accountToken", siteState.account.token, {expires: 7});

        // retrieve the customers saved wishlist, if they have one
        retrieveSavedWishlist();
      } else {
        // if there's no account in the siteState, remove the cookie, this allows users to log out
        // because this is in a useEffect rather than useLayoutEffect, it gets fired after the siteState has been set 👍
        Cookies.remove("accountToken");
      }
    }
  }, [siteState.account]);

  function readCartCookie() {
    const cartCookie = localStorage.getItem("M_CART");
    // console.log(cartCookie)

    if (cartCookie) {
      setSiteState((prevState) => ({
        ...prevState,
        cart: JSON.parse(cartCookie),
      }));
    }
  }

  function readWishlistCookie() {
    const wishlistCookie = localStorage.getItem("M_WISHLIST");

    if (wishlistCookie) {
      setSiteState((prevState) => ({
        ...prevState,
        wishlist: JSON.parse(wishlistCookie),
      }));
    }
  }

  function readAccountCookie() {
    const token = Cookies.get("accountToken");
    if (token) {
      axios({
        url: "/api/login-with-token",
        method: "post",
        data: {
          accessToken: token,
        },
      })
        .then((res) => {
          setSiteState((prevState) => ({
            ...prevState,
            account: res.data,
          }));
        })
        .catch((error) => {
          // if there's an error logging in, remove the cookie to prompt manual login, most likely the token has expired
          Cookies.remove("accountToken");
          console.log(error);
        });
    } else {
      // set to null rather than undefined, so that the for doesn't flash
      setSiteState((prevState) => ({
        ...prevState,
        account: null,
      }));
    }
  }

  function loadMaps() {
    // load Google maps
    const loader = new Loader(gmapsApiKey, {});
    loader.load().then(function (google) {
      setSiteState((prevState) => ({
        ...prevState,
        google: google,
      }));
    });
  }

  function retrieveSavedWishlist() {
    // Check to see if the current user has a wishlist
    if (siteState.account?.customer) {
      let query = `*[_type == "wishlist" && _id == "${siteState.account.customer.id}"]`;
      console.log(query);
      let products;
      client.fetch(query).then((res) => {
        if (res.length < 1) return null;
        let products = JSON.parse(res[0].wishlist);
        let productIds = products.map((p) => p.productId);
        console.log();
        // Get the actual product data from the id
        let productQuery = `*[_type == "product" && _id in ${JSON.stringify(productIds)}]{'variants': content.variants[]->{'price': content.price, 'id': _id, 'image': content.image}, ...} `;
        client.fetch(productQuery).then((products) => {
          console.log(products);
        });
      });
    }
    setWishlistRetrieved(true);
  }

  function saveWishlist() {
    // Only save if logged in
    if (siteState.account?.customer) {
      axios({
        url: "/.netlify/functions/save-wishlist",
        method: "post",
        data: {
          id: siteState.account.customer.id,
          firstName: siteState.account.customer.firstName,
          lastName: siteState.account.customer.lastName,
          email: siteState.account.customer.email,
          wishlist: JSON.stringify(
            siteState.wishlist.map((w) => ({
              productId: w.productId,
              variantId: w.variantId,
            })),
          ),
        },
      })
        .then((result) => {
          return null;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  // save the wishlist each time it changes
  useEffect(() => {
    if (init) {
      saveWishlist();
    }
  }, [siteState.wishlist.length]);

  // close Menu when search opens
  useEffect(() => {
    if (siteState.searchOpen) {
      setSiteState((prevState) => ({...prevState, menuOpen: false}));
    }
  }, [siteState.searchOpen]);

  // close Search when search opens
  useEffect(() => {
    if (siteState.menuOpen) {
      setSiteState((prevState) => ({...prevState, searchOpen: false}));
    }
  }, [siteState.menuOpen]);

  useLayoutEffect(() => {
    readCartCookie();
    readWishlistCookie();
    readAccountCookie();
    loadMaps();
    destroyCartIfPurchased();
    setInit(true);
  }, []);

  // set wholesale to true on login, if the customer has a wholesale tag in Shopify
  useEffect(() => {
    if (siteState.account?.customer?.tags?.includes("wholesale")) {
      setSiteState((prevState) => ({
        ...prevState,
        wholesale: true,
      }));
    } else {
      setSiteState((prevState) => ({
        ...prevState,
        wholesale: false,
      }));
    }
  }, [siteState.account]);

  // Set cart and wishlist localStorage
  useEffect(() => {
    if (init) {
      localStorage.setItem("M_CART", JSON.stringify(siteState.cart), {
        expires: 2,
      });
      localStorage.setItem("M_WISHLIST", JSON.stringify(siteState.wishlist), {
        expires: 2,
      });
    }
  }, [siteState]);

  return (
    <SiteContext.Provider
      value={{
        siteState: siteState,
        setSiteState: setSiteState,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

// hook to access siteState globally
export const useSiteState = () => {
  const {siteState, setSiteState} = useContext(SiteContext);
  return [siteState, setSiteState];
};

// hook to access account state globally
export const useAccount = () => {
  const {siteState, setSiteState} = useContext(SiteContext);

  const setAccount = (value) => {
    setSiteState({
      ...siteState,
      account: value,
    });
  };

  const logOut = () => {
    axios({
      url: "/api/logout",
      method: "post",
      data: {
        accessToken: siteState.account.token,
      },
    })
      .then((res) => {
        setSiteState({
          ...siteState,
          account: null,
        });
        Cookies.remove("accountToken");
      })
      .catch((error) => {
        // if there's an error logging in, remove the cookie to prompt manual login, most likely the token has expired
        Cookies.remove("accountToken");
        console.log(error);
      });
  };

  return {
    setAccount: setAccount,
    account: siteState.account,
    logOut: logOut,
  };
};

// Cart functions

export const useCart = () => {
  const {siteState, setSiteState} = useContext(SiteContext);

  const addItem = (item) => {
    let itemIndex = siteState.cart.findIndex((c) => c.variantId === item.variantId);
    if (itemIndex === -1) {
      setSiteState({
        ...siteState,
        cart: [...siteState.cart, item],
      });
      return true;
    } else if (siteState?.cart[itemIndex].maxQty === siteState?.cart[itemIndex].qty) {
      return false;
    } else {
      let newCart = siteState.cart;
      newCart[itemIndex].qty += item.qty;
      setSiteState({
        ...siteState,
        cart: newCart,
      });
      return true;
    }
  };

  const updateQty = (variantId, qty) => {
    let itemIndex = siteState.cart.findIndex((c) => c.variantId === variantId);
    if (itemIndex === -1) {
      return null;
    }
    let newCart = siteState.cart;
    newCart[itemIndex].qty = qty;
    setSiteState({
      ...siteState,
      cart: newCart,
    });
  };

  const removeItem = (variantId) => {
    let itemIndex = siteState.cart.findIndex((c) => c.variantId === variantId);
    if (itemIndex === -1) {
      return null;
    }
    let newCart = siteState.cart;
    newCart.splice(itemIndex, 1);
    setSiteState({
      ...siteState,
      cart: newCart,
    });
  };

  const cartCount = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue.qty;
    return [...siteState.cart].reduce(reducer, 0);
  };

  const cartTotal = () => {
    const reducer = (accumulator, currentValue) =>
      accumulator + currentValue.price * currentValue.qty;
    return [...siteState.cart].reduce(reducer, 0);
  };

  return {
    cart: siteState.cart,
    cartCount: cartCount,
    cartTotal: cartTotal,
    addItem: addItem,
    updateQty: updateQty,
    removeItem: removeItem,
  };
};

// Hook to access location
export const useUrlLocation = (props) => {
  const {siteState, setSiteState} = useContext(SiteContext);
  const urlLocation = useLocation();

  useEffect(() => {
    setSiteState((prevState) => ({
      ...prevState,
      location: urlLocation.pathname,
    }));
  }, [props]);
};

// Wishlist functions

export const useWishlist = () => {
  const {siteState, setSiteState} = useContext(SiteContext);

  const addRemoveWishlistItem = (item) => {
    // try to find an existing variant or product, need to check that if no variant is set, this will still equate to true
    let itemIndex = siteState.wishlist.findIndex(
      (c) => c.variantId === item.variantId && c.productSlug === item.productSlug,
    );

    if (itemIndex === -1) {
      setSiteState({
        ...siteState,
        wishlist: [...siteState.wishlist, item],
      });
    } else {
      let newWishlist = siteState.wishlist;
      newWishlist.splice(itemIndex, 1);
      setSiteState({
        ...siteState,
        wishlist: newWishlist,
      });
    }
  };

  const currentlyWishlisted = (item) => {
    // try to find an existing variant or product, need to check that if no variant is set, this will still equate to true
    if (item) {
      let itemIndex = siteState.wishlist.findIndex(
        (c) => c.variantId === item.variantId && c.productSlug === item.productSlug,
      );
      return itemIndex !== -1;
    } else {
      return false;
    }
  };

  const wishlistCount = () => {
    return siteState.wishlist.length;
  };

  const wishlistTotal = () => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue.price;
    return [...siteState.wishlist].reduce(reducer, 0);
  };

  return {
    wishlist: siteState.wishlist,
    addRemoveWishlistItem: addRemoveWishlistItem,
    wishlistCount: wishlistCount,
    wishlistTotal: wishlistTotal,
    currentlyWishlisted: currentlyWishlisted,
  };
};
