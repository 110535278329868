import React from "react";
import ReactDOM from "react-dom";
import {css} from "@emotion/react";
import {Transition} from "react-transition-group";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    this.setState({
      isVisible: true,
    });
  }

  render() {
    return ReactDOM.createPortal(
      <div
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.2);
          transition: opacity 0.5s;
          opacity: ${this.state.isVisible ? 1 : 0};
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 500;
          > * {
            transition: transform 0.5s;
            /* transition-delay: ${this.state.isVisible ? "0.2s" : "0s"}; */
            transform: ${this.state.isVisible ? "none" : "translateY(100px)"};
          }
        `}
      >
        {this.props.children}
      </div>,
      document.body,
    );
  }
}

const TransModal = (props) => (
  <Transition
    in={true}
    timeout={{
      appear: 500,
      enter: 300,
      exit: 3000,
    }}
  >
    <Modal {...props} />
  </Transition>
);

export default TransModal;
